// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableSeverities(severity) {
	const controller = '/pmseverity/disableSeverities';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		severity,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableSeverities(severity) {
	const controller = '/pmseverity/enableSeverities';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		severity,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
